<template>
  <div>
    <div class="row mt-5 voucher-home">
      <div class="col-12 col-lg-10 offset-lg-1 dual-box text-white p-4">
        <h3 class="mt-2">Pre vaccination consent form</h3>

        <p class="font-weight-bold">
          Please answer the following questions to ensure you are eligible for a
          vaccination.
        </p>

        <div class="mb-3">
          <b-form id="preassform" @submit.stop.prevent="onSubmit">
            <div
              class="mb-3 p-2"
              style="
                background-color: #f3f3f3;
                color: #525252;
                border-radius: 10px;
              "
            >
              <div class="d-flex mb-3 justify-content-between">
                <b-form-radio-group
                  style="align-items: flex-start"
                  v-model="preVaccine.liveVaccine"
                  :options="options"
                  name="radios-btn-default"
                  buttons
                ></b-form-radio-group>
                <div class="align-self-center flex-grow-1 ml-2">
                  Have you had any live vaccines in the last 3 weeks (yellow
                  fever, polio etc) (covid is <b>not</b> a live vaccination)?
                </div>
              </div>

              <div class="d-flex mb-3 justify-content-between">
                <b-form-radio-group
                  style="align-items: flex-start"
                  v-model="preVaccine.allergicReaction"
                  :options="options"
                  name="radios-btn-default"
                  buttons
                ></b-form-radio-group>
                <div class="align-self-center flex-grow-1 ml-2">
                  Have you ever had an allergic reaction to EGGS or CHICKEN
                  PROTEIN, or to GENTAMICIN, POLYMIXIN or NEOMYCIN (rarely used
                  antibiotics)?
                </div>
              </div>

              <div class="d-flex mb-3 justify-content-between">
                <b-form-radio-group
                  style="align-items: flex-start"
                  v-model="preVaccine.abnormalReactionFlu"
                  :options="options"
                  name="radios-btn-default"
                  buttons
                ></b-form-radio-group>
                <div class="align-self-center flex-grow-1 ml-2">
                  Have you had an abnormal reaction to any previous flu
                  immunisation?
                </div>
              </div>

              <div class="d-flex mb-3 justify-content-between">
                <b-form-radio-group
                  style="align-items: flex-start"
                  v-model="preVaccine.seriousAllergicReaction"
                  :options="options"
                  name="radios-btn-default"
                  buttons
                ></b-form-radio-group>
                <div class="align-self-center flex-grow-1 ml-2">
                  Have you ever had ANY serious allergic reaction?
                </div>
              </div>

              <!-- <div class="d-flex mb-3 justify-content-between">
                <b-form-radio-group
                  style="align-items: flex-start"
                  v-model="preVaccine.overSixtyFive"
                  :options="options"
                  name="radios-btn-default"
                  buttons
                ></b-form-radio-group>
                <div class="align-self-center flex-grow-1 ml-2">
                  Are you over 65?
                </div>
              </div> -->

              <div class="d-flex justify-content-between">
                <b-form-radio-group
                  style="align-items: flex-start"
                  v-model="preVaccine.pregnant"
                  :options="options"
                  name="radios-btn-default"
                  buttons
                ></b-form-radio-group>
                <div class="align-self-center flex-grow-1 ml-2">
                  Is there any possibility that you might be pregnant?
                </div>
              </div>
            </div>

            <div
              class="text-center font-weight-bold text-danger mb-3"
              v-if="message"
            >
              Please answer all pre vaccination questions
            </div>

            <div>
              <input
                type="submit"
                class="btn btn-light btn-lg text-hpcolour-green btn-block next"
                value="Submit"
              />
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      preVaccine: {
        liveVaccine: null,
        allergicReaction: null,
        abnormalReactionFlu: null,
        seriousAllergicReaction: null,
        // overSixtyFive: null,
        pregnant: null,
      },
      message: false,
      options: [
        { text: "Yes", value: "yes" },
        { text: "No", value: "no" },
      ],
    };
  },
  methods: {
    onSubmit() {
      this.message = false;
      for (const property in this.preVaccine) {
        if (this.preVaccine[property] === null) {
          this.message = true;
        }
        if (
          this.preVaccine[property] === "yes" // && property !== "overSixtyFive"
        ) {
          this.navigatePage("Refertogp");
          return;
        }
      }
      // if (this.preVaccine["overSixtyFive"] === "yes") {
      //   this.navigatePage("Oversixtyfive");
      //   return;
      // }
      if (this.message) {
        return;
      }
      this.navigatePage("Postcode", { code: this.$route.params.code });
    },
    navigatePage(page, params) {
      if (params) {
        this.$router.push({ name: page, params: params });
        return;
      }
      this.$router.push({ name: page });
    },
  },
};
</script>
